@import '~bootstrap/scss/functions';
@import '../../styles/_variables';

.heading {
  margin-top: 72px;
  text-align: center;
  font-size: 2.6rem;
  font-weight: 700;
}

.subHeading {
  text-align: center;
  font-size: 1.4rem;
  margin: 48px auto 62px;
  max-width: 800px;
}

.textH {
  font-weight: 700;
  text-decoration: underline;
}

.feature {
  display: flex;
  justify-content: space-between;
  margin-bottom: 72px;
  .item {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 900px) {
  .heading {
    font-size: 2.2rem;
  }
  .subHeading {
    font-size: 1.2rem;
  }
  .feature {
    flex-direction: column;
    .item {
      justify-content: center;
      margin-bottom: 22px;
    }
  }
}
