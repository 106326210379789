@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: 'liga', 'kern';
  text-rendering: optimizelegibility;
}

.fwb {
  font-weight: 700;
}

.text-underline {
  text-decoration: underline;
}

.fs1 {
  font-size: 1rem;
}
