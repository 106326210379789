@import '~bootstrap/scss/functions';
@import '../../styles/_variables';

.container {
  margin: 42px auto;
  max-width: 422px;
}

.list {
  list-style-type: none;
  padding: 0;
  & .listItem {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    padding-left: 52px;
    position: relative;
  }
}
.counter {
  background: $success;
  width: 32px;
  height: 32px;
  display: flex;
  float: left;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  position: absolute;
  left: 0;
}
